import React, { useContext, useEffect } from "react"

import { navigate } from "gatsby"
import styled, { ThemeContext, css } from "styled-components"

import { Heading, ListChoice, Stack, Text } from "@kiwicom/orbit-components"
import {
  Alert,
  ChevronForward,
  Circle,
  InformationCircle,
} from "@kiwicom/orbit-components/icons"

import { computeQuotePriceDetails } from "logic/quotes"

import { Quote } from "types/quote"

import { getTicketsFromSearchParams, isExpectedOnTime } from "utils/pass-utils"
import { quoteIsTooFarInThePast } from "utils/quote-utils"
import { useGlobalSetter, useGlobalState } from "utils/state-utils"
import { penniesInToPoundsStr } from "utils/string-utils"
import { updateQueryString } from "utils/url-utils"

const CustomChevronForward = styled(ChevronForward)`
  position: relative;
  top: 2px;
  left: 10px;
`

interface QuoteItemWrapperType {
  disabled: boolean
}

const QuoteItemWrapper = styled.div<QuoteItemWrapperType>`
  padding: ${(props) => props.theme.orbit.spaceSmall} 0px;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

interface QuoteItemType {
  quote: Quote
  context: "outboundBasket" | "returnBasket"
}

const QuoteItem = ({ quote, context }: QuoteItemType) => {
  const theme = useContext(ThemeContext)
  const { searchParams, outboundBasket, returnBasket } = useGlobalState()
  const state = useGlobalState()
  const basketSetter = useGlobalSetter(context)

  const originKey = `${context}Origin` // equals outboundBasketOrigin or returnBasketOrigin
  const destinationKey = `${context}Destination` // equals outboundBasketDestination or returnBasketDestination
  const scheduledTransitLeg = quote.scheduledTransitLeg

  const tickets = getTicketsFromSearchParams(searchParams)
  const totalLegs = quote.totalLegs

  const { price, selectable, message, availableSeats } =
    computeQuotePriceDetails({
      quote,
      tickets,
      outboundBasket,
      returnBasket,
    })

  if (quoteIsTooFarInThePast(quote)) {
    return null
  }

  const setBasket = () => {
    basketSetter({
      tickets: {
        adult: tickets.adult,
        child: tickets.child,
        youngChild: tickets.youngChild,
        concession: tickets.concession,
        wheelchair: tickets.wheelchair,
        bicycle: tickets.bicycle,
      },
      legs: quote.legs,
      price,
    })
  }

  useEffect(() => {
    const scheduledTransitLeg = quote.scheduledTransitLeg
    if (
      selectable &&
      state[originKey] === scheduledTransitLeg.origin.locationTimeId &&
      state[destinationKey] === scheduledTransitLeg.destination.locationTimeId
    ) {
      setBasket()
    }
  }, [state[originKey], state[destinationKey], selectable])

  const handleSelect = () => {
    if (selectable) {
      const query = {}
      const scheduledTransitLeg = quote.scheduledTransitLeg
      query[originKey] = scheduledTransitLeg.origin.locationTimeId
      query[destinationKey] = scheduledTransitLeg.destination.locationTimeId
      const { queryObject, queryString } = updateQueryString(
        window.location.search,
        query
      )
      if (queryString !== window.location.search) {
        navigate(`/book/${queryString}`, { state: queryObject })
      }
    }
  }

  return (
    <ListChoice
      onClick={handleSelect}
      disabled={!selectable}
      title={
        <QuoteItemWrapper disabled={!selectable} id="quote-item">
          <Stack
            direction="row"
            align="center"
            spacing="XSmall"
            justify="between"
          >
            <Stack direction="column" spacing="XSmall" shrink>
              <Stack
                direction="column"
                spacing="XXSmall"
                largeMobile={{
                  direction: "row",
                  align: "center",
                  spacing: "small",
                }}
              >
                <Heading type="title3" dataTest="quote-item-scheduled-times">
                  {quote.scheduledDepartureAtOrigin}
                  {" - "}
                  {quote.scheduledArrivalAtDestination}
                </Heading>
                {!isExpectedOnTime(quote) && selectable && (
                  <Stack
                    direction="row"
                    inline
                    align="center"
                    spacing="XXSmall"
                    dataTest="quote-item-estimated-times"
                  >
                    <Alert size="small" color="critical" />
                    <Text size="small" weight="bold" type="critical">
                      Est. {quote.expectedDepartureAtOrigin}
                      {" - "}
                      {quote.expectedArrivalAtDestination}
                    </Text>
                  </Stack>
                )}
                {totalLegs > 1 && (
                  <Text
                    type="secondary"
                    size="small"
                    dataTest="quote-item-num-changes"
                  >
                    {`${totalLegs - 1} ${
                      totalLegs == 2 ? "change" : "changes"
                    }`}
                  </Text>
                )}
              </Stack>
              {selectable ? (
                <Stack
                  direction="column"
                  spacing="XXSmall"
                  align="start"
                  largeMobile={{
                    direction: "row",
                    align: "center",
                    spacing: "small",
                  }}
                >
                  <QuoteItemMessage
                    message={message}
                    selectable={selectable}
                    availableSeats={availableSeats}
                    inline={true}
                  />
                  {scheduledTransitLeg.origin.preBookedOnly && (
                    <Stack direction="row" spacing="XXSmall" align="center">
                      <InformationCircle color="secondary" size="small" />
                      <Text dataTest="quote-item-pre-booked" type="secondary">
                        Pre-booked only
                      </Text>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <QuoteItemMessage
                  message={message}
                  selectable={selectable}
                  availableSeats={availableSeats}
                />
              )}
            </Stack>
            {/* Div is important to get alignment working */}
            {selectable && (
              <div>
                <Stack direction="row" align="center" spacing="XXSmall" shrink>
                  <Heading type="title2" dataTest="quote-item-price">
                    {penniesInToPoundsStr(price)}
                  </Heading>
                  <CustomChevronForward
                    size="medium"
                    customColor={theme.orbit.paletteProductNormal}
                  />
                </Stack>
              </div>
            )}
          </Stack>
        </QuoteItemWrapper>
      }
    ></ListChoice>
  )
}

function QuoteItemMessage({
  message,
  selectable,
  availableSeats,
  inline = false,
}: {
  message: string
  selectable: boolean
  availableSeats: number
  inline?: boolean
}) {
  const theme = useContext(ThemeContext)

  const availabilityColor = (): string => {
    if (selectable) {
      if (availableSeats > 6) {
        return theme.orbit.colorIconSuccess
      } else {
        return theme.orbit.colorIconWarning
      }
    } else {
      return theme.orbit.colorIconTertiary
    }
  }

  return (
    <Stack
      direction="row"
      spacing="XXSmall"
      align="center"
      dataTest="quote-item-message"
      inline={inline}
    >
      <Circle size="small" customColor={availabilityColor()} />
      <Text type="secondary">{message}</Text>
    </Stack>
  )
}

export default QuoteItem
