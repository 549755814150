// @ts-strict-ignore
import React from "react"

import { navigate } from "gatsby"
import moment from "moment"
import styled, { css } from "styled-components"

import { ArrowUp } from "@kiwicom/orbit-components/icons"

import { TimezoneContext, formatAsMediumDate } from "utils/date-utils"
import { useGlobalState } from "utils/state-utils"
import { updateQueryString } from "utils/url-utils"

const StyledDateSelector = styled.div`
  display: flex;
  min-height: ${(props) => props.theme.orbit.heightButtonNormal};
  border-radius: ${(props) =>
    props.theme.orbit.borderRadiusNormal +
    " " +
    props.theme.orbit.borderRadiusNormal +
    " 0 0"};
  background: ${(props) => props.theme.orbit.backgroundButtonPrimary};
  color: ${(props) => props.theme.orbit.paletteWhite};
`

type DateTitleProps = {
  fixedDate: boolean
}

const DateTitle = styled.div<DateTitleProps>`
  text-align: center;
  padding: ${(props) => props.theme.orbit.spaceSmall};
  font-weight: 500;
  flex: 1;
  ${(props) =>
    !props.fixedDate &&
    css`
      border-right-color: rgba(255, 255, 255, 0.3);
      border-right-style: solid;
      border-right-width: 1px;
      border-left-color: rgba(255, 255, 255, 0.3);
      border-left-style: solid;
      border-left-width: 1px;
    `}
`

type ChangeDateButtonProps = {
  direction: "previous" | "next"
  enabled: boolean
}

const ChangeDateButton = styled.div<ChangeDateButtonProps>`
  opacity: ${(props) => props.theme.orbit.opacityButtonDisabled};
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.direction == "previous" &&
    css`
      border-radius: 4px 0px 0 0;
      border-radius: ${(props) =>
        props.theme.orbit.borderRadiusNormal + " 0 0 0"};
    `}
  ${(props) =>
    props.direction == "next" &&
    css`
      border-radius: ${(props) =>
        "0 " + props.theme.orbit.borderRadiusNormal + " 0 0"};
    `}
  ${(props) =>
    props.enabled &&
    css`
      cursor: pointer;
      opacity: 1;
      &:hover {
        background: ${(props) =>
          props.theme.orbit.backgroundButtonPrimaryHover};
      }
    `}
`

const ArrowLeft = styled(ArrowUp)`
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
`

const ArrowRight = styled(ArrowUp)`
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
`

type DateSelectorProps = {
  date: string
  timezoneContext: TimezoneContext
  dateType?: "outbound" | "return"
  context?: any
  fixedDate?: boolean
  minDate?: string
  maxDate?: string
}

const DateSelector = ({
  date,
  timezoneContext,
  context = null,
  fixedDate = false,
  minDate = null,
  maxDate = null,
}: DateSelectorProps) => {
  const { searchParams } = useGlobalState()
  const navigateToDate = (date: string) => {
    const query = {}
    query[context] = date
    if (context == "departure_date" && searchParams.returnDate < date) {
      query["return_date"] = date
    } else if (context == "return_date" && searchParams.outDate > date) {
      query["departure_date"] = date
    }
    const { queryString, queryObject } = updateQueryString(
      window.location.search,
      query
    )

    if (queryString !== window.location.search) {
      navigate(`/book/${queryString}`, {
        state: queryObject,
      })
    }
  }

  const previousEnabled = moment(minDate) < moment(date).startOf("day")
  const nextEnabled = moment(date) < moment(maxDate)

  const subtractDate = () => {
    previousEnabled &&
      navigateToDate(moment(date).subtract(1, "days").format("YYYY-MM-DD"))
  }

  const addDate = () => {
    nextEnabled &&
      navigateToDate(moment(date).add(1, "days").format("YYYY-MM-DD"))
  }

  return (
    <StyledDateSelector>
      {!fixedDate && (
        <ChangeDateButton
          direction="previous"
          enabled={previousEnabled}
          onClick={subtractDate}
        >
          <ArrowLeft />
        </ChangeDateButton>
      )}

      <DateTitle fixedDate={fixedDate}>
        {formatAsMediumDate(date, timezoneContext)}
      </DateTitle>
      {!fixedDate && (
        <ChangeDateButton
          direction="next"
          enabled={nextEnabled}
          onClick={addDate}
        >
          <ArrowRight />
        </ChangeDateButton>
      )}
    </StyledDateSelector>
  )
}

export default DateSelector
