import React from "react"

import styled from "styled-components"

const LoadingSearchResult = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  padding: 27px 20px;
  border-bottom: ${(props) =>
    `${props.theme.orbit.borderWidthCard} ${props.theme.orbit.borderStyleCard} ${props.theme.orbit.borderColorCard}`};
  :last-child {
    border-bottom: none;
  }
`

const PlaceholderTimes = styled.div`
  height: 24px;
  width: 110px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.orbit.paletteInkLighter};
  animation: hint-loading 1.6s ease-in-out 0s infinite reverse;
  @keyframes hint-loading {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.1;
    }
  }
`

const PlaceholderCost = styled(PlaceholderTimes)`
  height: 30px;
  width: 54px;
  margin: 8px 8px 0 0;
  justify-self: end;
`

const PlaceholderAvailability = styled(PlaceholderTimes)`
  height: 12px;
  width: 160px;
`

const EmptySearchResults = () => (
  <>
    {[...Array(4).keys()].map((key) => (
      <LoadingSearchResult key={key}>
        <PlaceholderTimes />
        <PlaceholderCost />
        <PlaceholderAvailability />
      </LoadingSearchResult>
    ))}
  </>
)

export default EmptySearchResults
