// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import moment from "moment"
import styled, { css } from "styled-components"

import { OrbitProvider } from "@kiwicom/orbit-components"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import { fetchQuotes } from "api/quotes"

import { CheckoutForm } from "components/checkout-form"
import Container from "components/container"
import Layout from "components/layout"
import { OneWaySearchResults } from "components/one-way-search-results"
import { SearchBar } from "components/search-bar"

import DarkTheme from "styles/dark-theme"

import { formatDateForQuery } from "utils/date-utils"
import {
  SearchParamsType,
  useGlobalDispatch,
  useGlobalFetcher,
  useGlobalState,
} from "utils/state-utils"
import { useQueryString } from "utils/url-utils"

const SearchBarRow = styled.div`
  background: ${(props) => props.theme.orbit.paletteWhite};
`

const BookPage = ({ location }) => {
  const { queryString } = useQueryString()
  const { searchParams } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const [redirectToHome, setRedirectToHome] = useState(false)

  useEffect(() => {
    if (redirectToHome) {
      window.location.replace("/")
    }
  }, [redirectToHome])

  useEffect(() => {
    const url = window.location.href.split("/")
    if (url.includes("book") && !url.includes("confirm")) {
      const path = new URLSearchParams(window.location.search)
      const getIntParam = (name) =>
        path.has(name) ? parseInt(path.get(name)) : 0

      const adult = location.state?.adult
        ? parseInt(location.state.adult)
        : getIntParam("adult")
      const child = location.state?.child
        ? parseInt(location.state.child)
        : getIntParam("child")
      const youngChild = location.state?.youngChild
        ? parseInt(location.state.youngChild)
        : getIntParam("youngChild")
      const concession = location.state?.concession
        ? parseInt(location.state.concession)
        : getIntParam("concession")

      const wheelchair = location.state?.wheelchair
        ? parseInt(location.state.wheelchair)
        : getIntParam("wheelchair")
      const bicycle = location.state?.bicycle
        ? parseInt(location.state.bicycle)
        : getIntParam("bicycle")

      const totalPassengers = adult + child + youngChild + concession

      const origin = parseInt(
        location.state?.origin ? location.state.origin : path.get("origin")
      ) // Will be NaN if parameter doesn't exist
      const destination = parseInt(
        location.state?.destination
          ? location.state.destination
          : path.get("destination")
      ) // Will be NaN if parameter doesn't exist

      const outDate = location.state?.outDate
        ? location.state.outDate
        : path.get("departure_date")
      const returnDate = location.state?.returnDate
        ? location.state.returnDate
        : path.get("return_date")

      if (
        totalPassengers < 1 ||
        isNaN(origin) ||
        isNaN(destination) ||
        outDate === null ||
        !moment(outDate).isValid() ||
        (returnDate != null && !moment(returnDate).isValid())
      ) {
        setRedirectToHome(true)
      } else {
        const paramsFromURL: SearchParamsType = {
          origin: origin,
          destination: destination,
          outDate: outDate,
          returnDate: returnDate,
          adult: adult,
          child: child,
          youngChild: youngChild,
          concession: concession,
          wheelchair: wheelchair,
          bicycle: bicycle,
        }
        if (JSON.stringify(paramsFromURL) !== JSON.stringify(searchParams)) {
          dispatch({ searchParams: paramsFromURL })
        }
      }
    }
  }, [queryString])

  return (
    <Layout title="Choose Tickets" accountPage={true} requireState={true}>
      <>
        <OrbitProvider theme={DarkTheme}>
          <SearchBarRow>
            <Container size="extra-wide">
              <SearchBar initAsCollapsed={true} />
            </Container>
          </SearchBarRow>
        </OrbitProvider>
        <SearchResults />
      </>
    </Layout>
  )
}

const SearchResultsWrapper = styled(Container)`
  margin-top: 20px;
  grid-template-columns: 1fr;
  display: grid;
  gap: 30px;
  ${media.desktop(css`
    grid-template-columns: 2fr 360px;
    gap: 40px;
  `)}
`

const TripOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 40px;
  ${media.largeDesktop(
    css`
      flex-direction: row;
      > div {
        min-width: 480px;
      }
    `
  )}
`

const CheckoutWrapper = styled.div`
  margin-top: 0px;
  ${media.desktop(
    css`
      margin-top: 60px;
    `
  )}
`

export const SearchResults = () => {
  const { searchParams, outboundQuotes, returnQuotes } = useGlobalState()
  const { origin, destination, outDate, returnDate } = searchParams
  const { data: locations } = useGlobalFetcher("locations")
  const dispatch = useGlobalDispatch()
  const [outboundQuotesSubscription, setOutboundQuotesSubscription] =
    useState(null)
  const [returnQuotesSubscription, setReturnQuotesSubscription] = useState(null)

  useEffect(() => {
    dispatch({
      returnBasket: null,
      outboundBasket: null,
    })
  }, [
    searchParams.adult,
    searchParams.child,
    searchParams.youngChild,
    searchParams.concession,
    searchParams.wheelchair,
    searchParams.bicycle,
  ])

  useEffect(() => {
    if (outDate && origin && destination) {
      if (outboundQuotesSubscription) outboundQuotesSubscription.unsubscribe()

      const query = {
        origin: origin,
        destination: destination,
        departureDateFrom: formatDateForQuery(outDate, true).from,
        departureDateTo: formatDateForQuery(outDate, true).to,
        includeUnlistedTrips: true,
      }

      dispatch({
        outboundQuotes: null,
        outboundBasket: null,
      })

      const subscription = fetchQuotes({
        query,
        onError: () => dispatch({ outboundQuotes: [] }),
        onSuccess: ({ quotes }) => dispatch({ outboundQuotes: quotes }),
      })
      setOutboundQuotesSubscription(subscription)
    }
  }, [origin, destination, outDate])

  useEffect(() => {
    if (returnDate && origin && destination) {
      if (returnQuotesSubscription) returnQuotesSubscription.unsubscribe()
      const query = {
        origin: destination,
        destination: origin,
        departureDateFrom: formatDateForQuery(returnDate, true).from,
        departureDateTo: formatDateForQuery(returnDate, true).to,
        includeUnlistedTrips: true,
      }

      dispatch({
        returnQuotes: null,
        returnBasket: null,
      })

      const subscription = fetchQuotes({
        query,
        onError: () => dispatch({ returnQuotes: [] }),
        onSuccess: ({ quotes }) => dispatch({ returnQuotes: quotes }),
      })
      setReturnQuotesSubscription(subscription)
    }
  }, [origin, destination, returnDate])

  return (
    <SearchResultsWrapper size="extra-wide">
      <TripOptions>
        {origin && destination && locations && outDate && (
          <OneWaySearchResults
            prefix="out"
            basketContext="outboundBasket"
            dateContext="departure_date"
            from={locations?.find((location) => location.id == origin)?.name}
            to={locations?.find((location) => location.id == destination)?.name}
            searchDate={outDate}
            quotes={outboundQuotes}
          />
        )}
        {origin && destination && locations && returnDate && (
          <OneWaySearchResults
            prefix="return"
            basketContext="returnBasket"
            dateContext="return_date"
            from={
              locations?.find((location) => location.id == destination)?.name
            }
            to={locations?.find((location) => location.id == origin)?.name}
            searchDate={returnDate}
            quotes={returnQuotes}
          />
        )}
      </TripOptions>
      <CheckoutWrapper>
        <CheckoutForm />
      </CheckoutWrapper>
    </SearchResultsWrapper>
  )
}

export default BookPage
