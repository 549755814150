// @ts-strict-ignore
import React, { useEffect } from "react"

import { navigate } from "gatsby"
import moment from "moment-timezone"
import styled, { css } from "styled-components"

import { List, Stack, Text, TextLink } from "@kiwicom/orbit-components"
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery"

import DateSelector from "components/date-selector"

import { Tickets } from "types/pass"
import { Quote, QuoteType, selectQuotes } from "types/quote"

import {
  bookingFromDate,
  bookingToDate,
  formatAsMediumDate,
  getDefaultTimezone,
} from "utils/date-utils"
import { getTicketsFromSearchParams, getTripDeparture } from "utils/pass-utils"
import { useGlobalSetter, useGlobalState } from "utils/state-utils"
import { removeQueryStringParams, useQueryString } from "utils/url-utils"

import EmptySearchResults from "./placeholder-results"
import QuoteItem from "./quote-item"
import SelectedJourney from "./selected-journey"

const NoQuotesWrapper = styled.div`
  background: ${(props) => props.theme.orbit.paletteCloudLight};
  border-bottom: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  padding: 50px 30px;
`

const ResultsWrapper = styled.div`
  flex: 1;
`

const ResultsInnerWrapper = styled.div`
  background: ${(props) => props.theme.orbit.backgroundCard};
  border-radius: ${(props) =>
    `0px 0px ${props.theme.orbit.borderRadiusNormal} ${props.theme.orbit.borderRadiusNormal}`};
  border: ${(props) =>
    `${props.theme.orbit.borderWidthCard} ${props.theme.orbit.borderStyleCard} ${props.theme.orbit.borderColorCard}`};
  border-top: 0px;
`

const PaddedResultsInnerWrapper = styled(ResultsInnerWrapper)`
  padding: ${(props) => props.theme.orbit.spaceLarge};
`

const ResultsHeadingWrapper = styled.div`
  margin: 14px 0px 17px 0px; // Aligns exactly with checkout form
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px 5px;
  align-items: center;
  ${media.largeMobile(
    css`
      grid-template-columns: min-content 1fr;
    `
  )}
`

const ResultsHeading = styled.div`
  font-size: ${(props) => props.theme.orbit.fontSizeHeadingTitle3};
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Prefix = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.orbit.paletteProductNormal};
  text-transform: uppercase;
  padding-top: 2px;
`

const NextDayHeading = styled.div`
  background: ${(props) => props.theme.orbit.paletteCloudLight};
  border-bottom: 1px solid ${(props) => props.theme.orbit.borderColorCard};
  padding: 10px ${(props) => props.theme.orbit.spaceMedium};
  text-align: center;
`

const NoQuotesMessage = ({ message }) => (
  <NoQuotesWrapper>
    <Text size="large" align="center">
      {message}
    </Text>
  </NoQuotesWrapper>
)

function getProcessedQuotes(quotes: QuoteType[], tickets: Tickets): Quote[] {
  return selectQuotes(
    (quotes ?? []).map((quote) => new Quote(quote)),
    tickets
  )
}

interface SearchResultListType {
  quotes: Array<QuoteType>
  basketContext: "outboundBasket" | "returnBasket"
  searchDate: string
}

const SearchResultList = ({
  quotes,
  basketContext,
  searchDate,
}: SearchResultListType) => {
  const { searchParams } = useGlobalState()
  const tickets = getTicketsFromSearchParams(searchParams)

  if (!quotes) {
    return <EmptySearchResults />
  }

  const processedQuotes = getProcessedQuotes(quotes, tickets)

  const searchDateQuotes = processedQuotes.filter((quote) =>
    moment(getTripDeparture(quote)).isSameOrBefore(
      moment.tz(searchDate, getDefaultTimezone()).endOf("day")
    )
  )

  const followingDayQuotes = processedQuotes
    .filter((quote) =>
      moment(getTripDeparture(quote)).isAfter(
        moment.tz(searchDate, getDefaultTimezone()).endOf("day")
      )
    )
    .filter((quote) =>
      moment(getTripDeparture(quote)).isBefore(
        moment.tz(searchDate, getDefaultTimezone()).endOf("day").add(1, "day")
      )
    )

  return (
    <List>
      {searchDateQuotes.length == 0 ? (
        <NoQuotesMessage message="No services on this day" />
      ) : moment() >
        moment(
          getTripDeparture(searchDateQuotes[searchDateQuotes.length - 1])
        ) ? (
        <NoQuotesMessage message="No more services today" />
      ) : (
        searchDateQuotes.map((quote, i) => (
          <QuoteItem
            key={`${basketContext}-${i}`}
            quote={quote}
            context={basketContext}
          />
        ))
      )}
      {followingDayQuotes.length > 0 && (
        <NextDayHeading>
          <Stack direction="row" spacing="XSmall">
            <Text size="small" weight="bold">
              {formatAsMediumDate(
                moment(searchDate).add(1, "day").toISOString(),
                getDefaultTimezone()
              )}
            </Text>
            <Text size="small">(early morning services)</Text>
          </Stack>
        </NextDayHeading>
      )}
      {followingDayQuotes.map((quote, i) => (
        <QuoteItem
          key={`${basketContext}-${i}`}
          quote={quote}
          context={basketContext}
        />
      ))}
    </List>
  )
}

interface OneWaySearchResultsProps {
  prefix: "out" | "return"
  basketContext: "outboundBasket" | "returnBasket"
  searchDate: string
  dateContext: "departure_date" | "return_date"
  quotes: any[]
  from: string
  to: string
}

export const OneWaySearchResults = ({
  prefix,
  basketContext,
  searchDate,
  dateContext,
  quotes,
  from,
  to,
}: OneWaySearchResultsProps) => {
  const state = useGlobalState()
  const { queryString } = useQueryString()
  const originKey = `${basketContext}Origin` // equals outboundBasketOrigin or returnBasketOrigin
  const destinationKey = `${basketContext}Destination` // equals outboundBasketDestination or returnBasketDestination
  const basketSetter = useGlobalSetter(basketContext)
  const originSetter = useGlobalSetter(originKey)
  const destinationSetter = useGlobalSetter(destinationKey)
  const basket = state[basketContext]

  useEffect(() => {
    const path = new URLSearchParams(window.location.search)
    if (path.has("origin") && path.has("destination")) {
      let origin = null
      let destination = null
      if (path.has(originKey) && path.has(destinationKey)) {
        origin = parseInt(path.get(originKey))
        destination = parseInt(path.get(destinationKey))
      } else {
        basketSetter(null)
      }
      originSetter(origin)
      destinationSetter(destination)
    }
  }, [queryString])

  const clearBasket = () => {
    const updatedQueryString = removeQueryStringParams(window.location.search, [
      originKey,
      destinationKey,
    ])
    if (updatedQueryString !== window.location.search) {
      navigate(`/book/${updatedQueryString}`)
    }
  }

  const departure = basket?.legs[0].departure.scheduled

  return (
    <ResultsWrapper>
      <ResultsHeadingWrapper>
        <Prefix>{prefix}</Prefix>
        <ResultsHeading data-test={`results-heading-${prefix}`}>
          {from} to {to}
        </ResultsHeading>
      </ResultsHeadingWrapper>
      {basket ? (
        <Stack>
          <Stack spacing="none">
            <DateSelector
              date={departure}
              timezoneContext={getDefaultTimezone()}
              fixedDate={true}
            />
            <PaddedResultsInnerWrapper>
              <SelectedJourney
                legs={basket.legs}
                tickets={basket.tickets}
                price={basket.price}
              />
            </PaddedResultsInnerWrapper>
          </Stack>
          <TextLink type="secondary" onClick={() => clearBasket()}>
            Change selection
          </TextLink>
        </Stack>
      ) : (
        <Stack spacing="none">
          <DateSelector
            date={searchDate}
            timezoneContext={getDefaultTimezone()}
            context={dateContext}
            minDate={bookingFromDate(
              dateContext == "return_date" && state.outboundBasket
                ? state.outboundBasket.legs[
                    state.outboundBasket.legs.length - 1
                  ].arrival.scheduled
                : null
            )}
            maxDate={bookingToDate(
              dateContext == "departure_date" && state.returnBasket
                ? state.returnBasket.legs[0].departure.scheduled
                : null
            )}
          />
          <ResultsInnerWrapper>
            <SearchResultList
              basketContext={basketContext}
              quotes={quotes}
              searchDate={searchDate}
            />
          </ResultsInnerWrapper>
        </Stack>
      )}
    </ResultsWrapper>
  )
}
