import getTokens from "@kiwicom/orbit-components/lib/getTokens"

import emberTheme from "./ember-theme"
import { lightThemeFoundation } from "./light-theme"

const darkThemeFoundation = JSON.parse(JSON.stringify(lightThemeFoundation))

// Used for backgrounds in addition to cloud
darkThemeFoundation.palette.white = {
  normal: "#3c3e45",
  normalHover: "#39403F",
  normalActive: "#39403F",
}

// Used for backgrounds in addition to white
darkThemeFoundation.palette.cloud = {
  light: "#4f5259",
  lightHover: "#757985",
  lightActive: "#686c78",
  normal: "#5c606b",
  normalHover: "#666a75",
  normalActive: "#5e626e",
  dark: "#595c66",
  darkHover: "#50535c",
  darkActive: "#4a4d57",
}

darkThemeFoundation.palette.ink = {
  lighter: "#787c82", // e.g. field placeholder text and borders
  lighterHover: "#8b9199",
  lighterActive: "#A4AAB0",
  light: "#bdc1c9", // e.g. field labels
  lightHover: "#D7DAE0",
  lightActive: "#C7CAD1",
  normal: "#f5f8fa", // e.g. field input values
  normalHover: "#e6e9eb",
  normalActive: "#e1e4e6",

  dark: "#f5f8fa", // this will be the FG colour for Ember's `NavBar`
}

// Replace blue with light grey (same as ink) since it's used for active states
darkThemeFoundation.palette.blue = {
  normal: "#787c82",
  normalHover: "#8b9199",
  normalActive: "#A4AAB0",
}

const tokens = getTokens(darkThemeFoundation)

tokens.lineHeightTextSmall = "20px"
tokens.lineHeightTextNormal = "22px"
tokens.lineHeightTextLarge = "24px"
tokens.fontSizeFormLabel = "14px"
tokens.fontSizeHeadingTitle3 = "18px"

const theme = {
  ember: emberTheme,
  orbit: tokens,
}

export default theme
