// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import * as Sentry from "@sentry/gatsby"
import { navigate } from "gatsby"

import {
  Heading,
  Loading,
  Stack,
  Text,
  TextLink,
} from "@kiwicom/orbit-components"
import { Document, Replace, Suitcase } from "@kiwicom/orbit-components/icons"

import { OrderWithPaymentDetails, submitOrder } from "api/orders"

import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import {
  PaymentForm,
  createPaymentIntentParams,
} from "components/shared/payment-form"

import { computeStoreCreditUsageForPurchase } from "logic/prices"

import { Order } from "types/order"

import { useGlobalDispatch, useGlobalState } from "utils/state-utils"
import { arrayToString, penniesInToPoundsStr } from "utils/string-utils"

const routeToConfirmationPage = (dispatch, order: Order) => {
  const sumPrice = order.passes.reduce((sum, pass) => sum + pass.price, 0)

  // Submit Google Analytics event
  if (typeof window !== "undefined") {
    const gtag = window.gtag
    if (gtag != null) {
      gtag("event", "purchase", {
        currency: "GBP",
        value: sumPrice / 100,
        transaction_id: order.uid,
      })
    }
  }

  // Redirect to confirmation page
  dispatch({ initialOrder: order.passes })
  dispatch({ initialOrderUid: order.uid })
  const searchParams = new URLSearchParams({
    order: order.uid,
    confirm: "true",
  }).toString()
  try {
    window.localStorage.setItem(
      "confirmPageParams",
      new URLSearchParams({
        order: order.uid,
        confirm: "true",
      }).toString()
    )
  } catch (error) {
    console.error(error)
  }
  const target = `/confirm/?${searchParams}`
  Sentry.addBreadcrumb({
    category: "preNavigation",
    message: target,
    level: "info",
  })
  navigate(target, {
    state: {
      order: order.uid,
      confirm: true,
    },
  })
}

export const CheckoutForm = () => {
  const dispatch = useGlobalDispatch()
  const { outboundBasket, returnBasket, searchParams, balance } =
    useGlobalState()
  const {
    returnDate,
    adult,
    concession,
    child,
    youngChild,
    wheelchair,
    bicycle,
  } = searchParams

  const [allTicketsSelected, setAllTicketsSelected] = useState(false)
  const [isComplete, setComplete] = useState(false)

  const outPrice = outboundBasket ? outboundBasket.price : 0
  const returnPrice = returnBasket ? returnBasket.price : 0
  const totalBalance = balance ? balance.total : 0
  const sumPrice = outPrice + returnPrice
  const storeCreditUsage = computeStoreCreditUsageForPurchase({
    price: sumPrice,
    availableStoreCredit: totalBalance,
  })
  const netCharge = sumPrice - storeCreditUsage
  const paymentPotentiallyRequired = adult > 0 || child > 0 || concession > 0

  const basket = [outboundBasket]
  if (returnBasket) {
    basket.push(returnBasket)
  }
  const passes = basket.map((item) => {
    const pass = Object.assign({}, item)
    // @ts-expect-error: Basket objects don't have an `origin` field, could this be removed actually?
    delete pass.origin
    // @ts-expect-error: same
    delete pass.destination
    return pass
  })

  const createPaymentIntent = ({
    payer,
    paymentMethod,
    onPaymentIntentCreated,
    onPaymentIntentError,
  }: createPaymentIntentParams<Order>) => {
    submitOrder({
      request: {
        passes,
        customer: { ...payer, balance },
        method: paymentMethod,
        storeCreditUsage,
      },
      onSuccess: (order: OrderWithPaymentDetails) =>
        onPaymentIntentCreated({
          paymentDetails: order.paymentDetails,
          payload: order,
        }),
      onError: onPaymentIntentError,
    })
  }

  useEffect(() => {
    if (outboundBasket && (!returnDate || returnBasket)) {
      setAllTicketsSelected(true)
    } else {
      setAllTicketsSelected(false)
    }
  }, [returnDate, JSON.stringify(basket)])

  let totalChargeString: string
  if (allTicketsSelected) {
    totalChargeString = penniesInToPoundsStr(sumPrice)
  } else {
    totalChargeString = "£-"
  }

  const reservationsString = arrayToString([
    { value: adult, singular: "adult", plural: "adults" },
    { value: concession, singular: "concession", plural: "concessions" },
    { value: child, singular: "child", plural: "children" },
    { value: youngChild, singular: "young child", plural: "young children" },
    {
      value: wheelchair,
      singular: "wheelchair space",
      plural: "wheelchair spaces",
    },
    { value: bicycle, singular: "bike space", plural: "bike spaces" },
  ])

  let chooseJourneyString: string
  if (!allTicketsSelected) {
    if (outboundBasket && returnDate && !returnBasket) {
      chooseJourneyString = "Choose a return journey to continue"
    } else if (!outboundBasket && returnDate && !returnBasket) {
      chooseJourneyString = "Choose an outbound and return journey to continue"
    } else if (!outboundBasket && returnDate && returnBasket) {
      chooseJourneyString = "Choose an outbound journey to continue"
    } else if (!outboundBasket && !returnDate) {
      chooseJourneyString = "Choose a journey to continue"
    }
  }

  if (isComplete) {
    // Once the payment has been taken, hide the form while we're redirecting
    // to the confirmation page
    return <Loading text="Almost done..." />
  }

  return (
    <Stack spacing="XLarge">
      <EmberCard
        header={
          <Stack spacing="small">
            <Stack direction="row" justify="between">
              <Heading type="title2">Total</Heading>
              <Heading dataTest="total-charge-string" type="title2">
                {totalChargeString}
              </Heading>
            </Stack>
            <Text dataTest="checkout-reservation-string">
              For {reservationsString}
            </Text>
          </Stack>
        }
      >
        {storeCreditUsage > 0 && (
          <EmberCardSection>
            <Stack spacing="small">
              <Stack direction="row" justify="between">
                <Text weight="medium">Account credit used</Text>
                <Text dataTest="account-credit-used" weight="medium">
                  {penniesInToPoundsStr(storeCreditUsage)}
                </Text>
              </Stack>
              <Stack direction="row" justify="between">
                <Text weight="medium">Total to pay</Text>
                <Text dataTest="total-to-pay" weight="medium">
                  {penniesInToPoundsStr(sumPrice - storeCreditUsage)}
                </Text>
              </Stack>
            </Stack>
          </EmberCardSection>
        )}
        <EmberCardSection>
          {allTicketsSelected ? (
            <PaymentForm
              netCharge={netCharge}
              storeCreditUsage={storeCreditUsage}
              paymentPotentiallyRequired={paymentPotentiallyRequired}
              createPaymentIntent={createPaymentIntent}
              onPaymentComplete={(order: Order) => {
                setComplete(true)
                routeToConfirmationPage(dispatch, order)
              }}
            />
          ) : (
            <Text>{chooseJourneyString}</Text>
          )}
        </EmberCardSection>
      </EmberCard>
      <Stack spacing="small">
        <Stack direction="row" align="center" spacing="small">
          <Replace />
          <Text size="small">Change or cancel your ticket for free</Text>
        </Stack>
        <Stack direction="row" align="center" spacing="small">
          <Suitcase />
          <Text size="small">
            Includes a large suitcase or two medium bags per person (contact us
            if you have more luggage)
          </Text>
        </Stack>
        <Stack direction="row" align="center" spacing="small">
          <Document />
          <Text size="small">
            By making a booking you accept our{" "}
            <TextLink type="secondary" external href="/privacy-policy/">
              Privacy Policy
            </TextLink>{" "}
            and{" "}
            <TextLink type="secondary" external href="/conditions-of-carriage/">
              Conditions of Carriage
            </TextLink>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}
